import React from "react"
import { Link, graphql } from 'gatsby'
import * as LinkList from 'components/linklist/link-list.module.scss'
import loadable from '@loadable/component'

import BaseLayout from 'layouts/base.layout'
import ComponentLayout from 'layouts/component.layout'

/* Components */
import SEO from "components/seo"
import LoadingIndicator from 'components/loading-indicator'
import StageComponent from 'components/stage'
import LinkComponent from 'components/link'

/* Fontawesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faUserChart, faUserAstronaut, faCode, faAnalytics, faPencilRuler, faDatabase } from '@fortawesome/pro-duotone-svg-icons'
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false
library.add(
  faUserChart,
  faUserAstronaut,
  faCode,
  faAnalytics,
  faPencilRuler,
  faDatabase
)

const BenefitsComponent = loadable(() => import('components/benefits'))
const StepByStepComponent = loadable(() => import('components/step-by-step'))

const JobsPage = (props) => {
  return (
    <BaseLayout>
      <SEO title="Jobs" url={ props.location.href } />
      <StageComponent props={ props.data.stage } />
      <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
          <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
      </svg>
      <div className="bg-white">
        <StepByStepComponent fallback={<LoadingIndicator />} props={ props.data.stepbystep }/>
        <BenefitsComponent fallback={<LoadingIndicator />} props={ props.data.benefits } />
      </div>
    </BaseLayout>
  )
}

export default JobsPage

export const query = graphql`
  query {
    stage: contentfulStage(page: {eq: "Jobs"}) {
      title
      subtitle
    }

    benefits: allContentfulBenefitsModule (
      filter: {page: {eq: "Jobs"}}
    ) {
      ...benefitsQuery
    }

    stepbystep: allContentfulStepByStepModul (
      filter: {page: {eq: "Jobs"}}
    ) {
      ...stepByStepQuery
    }
  }
`
